import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import type { WidgetLocation } from '@aurora/shared-client/components/context/SectionWidgetContext';
import SectionWidgetContext from '@aurora/shared-client/components/context/SectionWidgetContext';
import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import type {
  MainSideQuiltSection,
  OneColumnQuiltSection,
  QuiltComponent as QuiltComponentType,
  SideMainQuiltSection,
  ThreeColumnQuiltSection,
  TwoColumnQuiltSection
} from '@aurora/shared-generated/types/graphql-schema-types';
import { SectionLayoutType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { SectionLayout } from '@aurora/shared-types/quilts';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import { getLog } from '@aurora/shared-utils/log';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, useClassNameMapper } from 'react-bootstrap';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import {
  getSectionKey,
  getSectionLayout,
  getSectionWidgetRenderState,
  setContentItemBorderCssVar
} from '../../../helpers/quilt/SectionHelper';
import { getWidgetClass } from '../../../helpers/quilt/WidgetHelper';
import useSection from '../../useSection';
import useTranslation from '../../useTranslation';
import type { FirstSectionComponents } from '../Quilt/QuiltContainerRenderer';
import QuiltComponent from '../QuiltComponent/QuiltComponent';
import QuiltSectionHeader from '../QuiltSectionHeader/QuiltSectionHeader';
import localStyles from './QuiltSection.module.pcss';

const log = getLog(module);

export type SectionType =
  | MainSideQuiltSection
  | SideMainQuiltSection
  | OneColumnQuiltSection
  | TwoColumnQuiltSection
  | ThreeColumnQuiltSection;

export interface SectionProps {
  /**
   * The section to display
   */
  section: SectionType;

  /**
   * Id of the quilt
   */
  quiltId: string;

  /**
   * Lazy load this element if true.
   */
  lazyLoad?: boolean;

  /**
   * The list of components to render as part of the first visible section of the quilt
   */
  componentsToRenderFirstInFirstVisibleSection?: FirstSectionComponents;

  /**
   * @callback
   * A callback called when a section is not visible.
   *
   * @param id the section id.
   */
  onSectionInvisible: (id: string) => void;

  /**
   * The section id of the first section to render.
   */
  firstVisibleSectionId: string;
}

/**
 * Returns unique render state instance id of the widget based on its location on the page
 * This is only used for render.
 * @param widgetId
 * @param sectionId
 * @param columnId
 * @param widgetIdx
 */
function getRenderStateWidgetInstanceId(
  widgetId: string,
  sectionId: string,
  columnId: string,
  widgetIdx: number
): string {
  return `${sectionId}-${columnId}-${widgetId}-${widgetIdx}`;
}

/**
 *  Renders an individual quilt section using the Bootstrap grid system https://react-bootstrap.github.io/layout/grid/
 *
 *  <section> Element adds background image, background color, top and bottom borders, and padding. These are specified
 *    in the quilt by "bgImage", "bgColor", "showBorder", and "disableSpacing". Both "showBorder" and "disableSpacing"
 *    have valid values of "top", "bottom", and "both". When no values are provided for "showBorder", no borders are
 *    applied, whereas if no value for "disableSpacing" is provided, 25px of padding is applied to the top and bottom
 *    of the section. Provided "fullHeight" for a section, applies additional flex styles to the section, Container,
 *    Row, Column, and widget that allow these elements to receive the specified proportion of the free space in the
 *    container.
 *    <ThemedContainer> Element centers and horizontally pads section content. Provided "edgeToEdgeDisplay" in the quilt, sets
 *      "fluid" in the Container API, which removes horizontal padding and allows full-screen width for the contents.
 *      <div> element conditionally renders depending on if "title" or "description" are present.
 *        <h3>title</h3>
 *        <span>description</span>
 *      </div>
 *      <Row> Provided "edgeToEdgeDisplay" sets "noGutters" in the Row API, removing spacing and negative margins
 *        between columns
 *        <Col> The number of columns and the colspan is specified by the "layout" param.
 *          <Component 1 />
 *          <Component 2 />
 *        </Col>
 *        <Col>
 *          <Component 3 />
 *        <Col>
 *      </Row>
 *    </ThemedContainer>
 *  </section>
 *
 * @constructor
 *
 * @author Jonathan Bridges
 */
const QuiltSection: React.FC<React.PropsWithChildren<SectionProps>> = ({
  section,
  quiltId,
  lazyLoad,
  componentsToRenderFirstInFirstVisibleSection = {
    components: [],
    attachFirstComponentToTop: false
  },
  firstVisibleSectionId,
  onSectionInvisible
}) => {
  const cx = useClassNameMapper(localStyles);
  const { theme } = useContext(ThemeContext);
  const textVariantContext = useContext(TextVariantContext);
  const { pageId } = useContext(PageContext);

  const { formatMessage, loading, hasMessage } = useTranslation(
    endUserComponentRegistry.getComponentIdForPage(pageId)
  );

  const {
    id: sectionId,
    layout = SectionLayoutType.OneColumn,
    fullHeight,
    showTitle,
    showDescription,
    textColor = CommonColorCssVariables.BODY_TEXT,
    textPosition,
    columnMap = { main: [] },
    edgeToEdgeDisplay
  } = section;

  const sectionWidgetsRenderState: Partial<Record<string, boolean>> = getSectionWidgetRenderState(
    layout,
    (columnId: string) => {
      return columnMap[columnId];
    },
    {},
    sectionId
  );

  const sectionLayout: SectionLayout = getSectionLayout(layout);
  const { columns } = sectionLayout;

  const [widgetsRenderState, setWidgetsRenderState] =
    useState<Partial<Record<string, boolean>>>(sectionWidgetsRenderState);

  const sectionHeaderVisibilityClassName = 'lia-g-mb-15';
  const [sectionVisibilityClassName, setSectionVisibilityClassName] = useState<string>();
  const [sectionHeaderClassName, setSectionHeaderClassName] = useState<string>(
    sectionHeaderVisibilityClassName
  );

  const titleKey = getSectionKey(sectionId, 'title');
  const title = hasMessage(titleKey) ? formatMessage(titleKey) : null;

  const descriptionKey = getSectionKey(sectionId, 'description');
  const description = hasMessage(descriptionKey) ? formatMessage(descriptionKey) : null;

  const widgetNotRenderedCallback = useCallback(
    (widgetId: string, visibilityStatus: boolean, renderStateInstanceId: string) => {
      if (widgetsRenderState[renderStateInstanceId] !== visibilityStatus) {
        setWidgetsRenderState(previousState => {
          return { ...previousState, [renderStateInstanceId]: visibilityStatus };
        });
      }
    },
    [widgetsRenderState]
  );

  /**
   * Sets proper classname for section to show/hide section based on the whether any widgets rendered within it or not and also
   * whether the title and description is present and visible or not. Also, sets proper section header classname based on the visibility
   * of title and description
   *
   */
  useEffect(() => {
    const isAnyWidgetRendered = Object.entries(widgetsRenderState).some(
      ([, renderState]) => renderState
    );
    if (!isAnyWidgetRendered) {
      const hasSrTitle: boolean = !!title && !showTitle;
      const hasSrDescription: boolean = !!description && !showDescription;

      const isSrOnly =
        (hasSrTitle && hasSrDescription) ||
        (hasSrTitle && (!description || !showDescription)) ||
        (hasSrDescription && (!title || !showTitle));

      // Does not display title or description either because none of them is defined or both are chosen to be not shown
      const isDNone = (!title || !showTitle) && (!description || !showDescription);

      if (isSrOnly) {
        setSectionVisibilityClassName('sr-only');
        setSectionHeaderClassName('lia-g-mb-0');
        if (componentsToRenderFirstInFirstVisibleSection.components.length > 0) {
          onSectionInvisible(sectionId);
        }
      } else if (isDNone) {
        setSectionVisibilityClassName('d-none');
        setSectionHeaderClassName('lia-g-mb-0');
        if (componentsToRenderFirstInFirstVisibleSection.components.length > 0) {
          onSectionInvisible(sectionId);
        }
      }
    } else {
      setSectionHeaderClassName(sectionHeaderVisibilityClassName);
      setSectionVisibilityClassName(null);
    }
  }, [
    componentsToRenderFirstInFirstVisibleSection.components.length,
    description,
    onSectionInvisible,
    sectionId,
    showDescription,
    showTitle,
    title,
    widgetsRenderState
  ]);

  const {
    sectionBackgroundStyles,
    sectionClassNames,
    containerClassNames,
    rowClassNames,
    columnClassNames
  } = useSection(section, quiltId);

  /**
   * Renders the widget
   */
  function renderWidget(
    columnItem: QuiltComponentType,
    columnId: string,
    columnIdx: number
  ): React.ReactElement {
    const widgetLocation: WidgetLocation = {
      sectionId,
      columnId,
      widgetIdx: columnIdx
    };

    const renderStateInstanceId = getRenderStateWidgetInstanceId(
      columnItem.id,
      sectionId,
      columnId,
      columnIdx
    );

    function isWidgetVisibleCallback(visible: boolean): void {
      if (columnItem.id) {
        widgetNotRenderedCallback(columnItem.id, visible, renderStateInstanceId);
      }
    }

    return (
      <SectionWidgetContext.Provider
        key={`${sectionId}-${layout}-${columnId}-${columnItem.id}-${columnIdx}`}
        value={{ location: widgetLocation, fullHeight }}
      >
        <TextVariantContext.Provider
          value={{ ...textVariantContext, instance: columnItem?.props?.instanceId }}
        >
          {widgetsRenderState[renderStateInstanceId] && (
            <div className={cx(getWidgetClass(fullHeight))}>
              <QuiltComponent
                component={columnItem}
                isVisible={isWidgetVisibleCallback}
                lazyLoad={lazyLoad}
              />
            </div>
          )}
        </TextVariantContext.Provider>
      </SectionWidgetContext.Provider>
    );
  }

  if (loading) {
    return null;
  }

  if (sectionLayout) {
    return (
      <section
        style={sectionBackgroundStyles}
        className={cx(sectionClassNames, sectionVisibilityClassName)}
        data-testid={`QuiltSection-${section.id}`}
      >
        {firstVisibleSectionId === sectionId &&
          componentsToRenderFirstInFirstVisibleSection.components.map((Component, idx) => {
            if (
              componentsToRenderFirstInFirstVisibleSection.attachFirstComponentToTop &&
              idx === 0
            ) {
              return (
                <div className={cx('lia-section-attached-first')} key={`${pageId}-${idx}`}>
                  <Component />
                </div>
              );
            } else {
              return <Component key={`${pageId}-${idx}`} />;
            }
          })}
        <ThemedContainer fluid={edgeToEdgeDisplay} className={cx(containerClassNames)}>
          {(title || description) && (
            <QuiltSectionHeader
              title={{ value: title, showTitle }}
              description={{ value: description, showDescription }}
              textColor={textColor}
              textPosition={textPosition}
              className={sectionHeaderClassName}
            />
          )}
          <Row className={cx(rowClassNames)} noGutters={edgeToEdgeDisplay}>
            {columns.map(column => {
              const { id: columnId, xs, sm, md, lg, xl } = column;
              return (
                <Col
                  style={setContentItemBorderCssVar(theme, columnId)}
                  className={cx(columnClassNames)}
                  key={`${sectionId}-${layout}-${columnId}`}
                  xs={xs}
                  sm={sm}
                  md={md}
                  lg={lg}
                  xl={xl}
                >
                  {columnMap[columnId]?.map((columnItem, columnIdx) =>
                    renderWidget(columnItem, columnId, columnIdx)
                  )}
                </Col>
              );
            })}
          </Row>
        </ThemedContainer>
      </section>
    );
  } else {
    log.error(
      'section layout not found for section with id %s and layout name %s',
      sectionId,
      layout
    );
    return null;
  }
};
export default QuiltSection;
