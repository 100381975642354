import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { QuiltSection } from '@aurora/shared-generated/types/graphql-schema-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import {
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize
} from '@aurora/shared-types/images/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import type { CSSProperties } from 'react';
import { useContext } from 'react';
import { AssetFeature, getQuiltAssetURL } from '../helpers/util/AssetHelper';

type BackgroundCssProperties = Pick<CSSProperties, 'background' | 'minHeight'>;

interface SectionProps {
  /**
   * Classnames to apply to section tag.
   */
  sectionClassNames: string[];

  /**
   * Classnames to apply to container of the section.
   */
  containerClassNames: string[];

  /**
   * Classnames to apply to the rows of the section.
   */
  rowClassNames: string[];

  /**
   * Classnames to apply to the columns of the sections.
   */
  columnClassNames: string[];

  /**
   * Style values for the background image and color of a section.
   */
  sectionBackgroundStyles: BackgroundCssProperties;
}

/**
 * Hook to share common logic between edit mode and normal mode for section.
 *
 * @author Manish Shrestha
 */
export default function useSection(section: QuiltSection, quiltId: string): SectionProps {
  const tenant = useContext(TenantContext);
  const {
    bgImage,
    bgColor = CommonColorCssVariables.TRANSPARENT,
    disableSpacing,
    showBorder,
    fullHeight,
    edgeToEdgeDisplay
  } = section;

  const { branchName } = useContext(SwitchBranchContext);
  // background image and color are applied via the React inline style tag
  const sectionBgImageStyleObject: BackgroundCssProperties = {};
  if (bgImage?.assetName) {
    const { assetName, backgroundPosition, backgroundSize, backgroundRepeat, lastModified } =
      bgImage;
    const timestamp =
      typeof lastModified === 'string' ? lastModified : LastModifiedKeys.DEFAULTS_ONLY;
    const imageUrl = getQuiltAssetURL(
      tenant,
      quiltId,
      assetName,
      AssetFeature.QUILTS,
      timestamp,
      branchName
    );
    sectionBgImageStyleObject.background = `url(${imageUrl}) ${BackgroundPosition[backgroundPosition]} / ${BackgroundSize[backgroundSize]} ${BackgroundRepeat[backgroundRepeat]}`;

    sectionBgImageStyleObject.minHeight = '200px';
  }

  // background color
  if (bgColor) {
    const { background } = sectionBgImageStyleObject;
    const computedBackgroundColor = `${
      bgColor !== CommonColorCssVariables.TRANSPARENT ? bgColor : ''
    } ${background || ''}`.trim();
    if (computedBackgroundColor) {
      sectionBgImageStyleObject.background = computedBackgroundColor;
    }
  }

  // top and bottom padding applied to the section
  const sectionSpacingClassName = disableSpacing
    ? `lia-section-spacing-${disableSpacing.toLowerCase()}-disabled`
    : 'lia-section-spacing';

  // top and bottom borders for the section
  const sectionBorderClassName = showBorder
    ? `lia-section-border-${showBorder.toLowerCase()}`
    : null;

  // applies additional flex styles (see component JS Doc)
  const heightClassName = fullHeight ? 'lia-g-page-full-height' : null;
  const columnClassName = heightClassName ? `lia-column ${heightClassName}` : 'lia-column';

  const containerClassNames: string[] = [heightClassName];
  if (edgeToEdgeDisplay) {
    containerClassNames.push('lia-g-px-0');
  }
  return {
    sectionClassNames: [heightClassName, sectionSpacingClassName, sectionBorderClassName],
    containerClassNames,
    rowClassNames: [heightClassName],
    columnClassNames: [columnClassName],
    sectionBackgroundStyles: sectionBgImageStyleObject
  };
}
