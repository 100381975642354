import { buildPrefixedQuiltBundleId } from '@aurora/shared-apollo/helpers/ApolloQuiltCacheHelper';
import type { CachedAssetFragment } from '@aurora/shared-generated/types/graphql-types';
import { useContext } from 'react';
import TenantContext from './context/TenantContext';
import useCachedFragment from './useCachedFragment';

/**
 * Hook for fetch a quilt bundle from the Apollo cache
 * @param pageId The ID of the page
 * @param node Node information
 * @param lastModified Last modified timestamp
 * @returns Cached quilt, or null
 * @author Rosalyn Rowe
 */
export default function useCachedQuiltBundle(
  pageId: string,
  node: string,
  lastModified: string
): CachedAssetFragment {
  const tenant = useContext(TenantContext);
  const bundleId = buildPrefixedQuiltBundleId(tenant.id, pageId, node, lastModified);

  const cachedBundle = useCachedFragment(bundleId);

  return cachedBundle;
}
