import QuiltWrapperContext from '@aurora/shared-client/components/context/QuiltWrapperContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import {
  BackgroundSize as BackgroundSizeEnum,
  BackgroundRepeat as BackgroundRepeatEnum,
  BackgroundPosition as BackgroundPositionEnum
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { BackgroundImageProps } from '@aurora/shared-generated/types/graphql-schema-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import {
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize
} from '@aurora/shared-types/images/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import type { CSSProperties } from 'react';
import { useContext } from 'react';
import { AssetFeature, getQuiltAssetURL } from '../helpers/util/AssetHelper';

type BackgroundCssProperties = Pick<CSSProperties, 'background' | 'opacity'>;

interface SectionWrapperProps {
  /**
   * Style values for the background image and color of a section.
   */
  sectionBackgroundStyles: BackgroundCssProperties;
}

interface QuiltWrapperSection {
  /**
   * Background color for the quilt wrapper section
   */
  backgroundColor?: string;
  /**
   * Background image props for the quilt wrapper section
   */
  backgroundImageProps?: Partial<BackgroundImageProps>;
}

/**
 * Hook to share common logic between edit mode and normal mode for wrapper section.
 *
 * @author Luisina Santos
 */
export default function useWrapperSection(
  quiltWrapperId: string,
  props: Omit<QuiltWrapperSection, 'items'> = {},
  backgroundOpacity: number = null,
  backgroundImageUrl: string = null,
  useInheritedBackgroundImageProps: boolean = false
): SectionWrapperProps {
  const tenant = useContext(TenantContext);
  const quiltWrapper = useContext(QuiltWrapperContext);
  const { branchName } = useContext(SwitchBranchContext);
  const { backgroundColor, backgroundImageProps } = props;

  const inheritedBackgroundImageProps = useInheritedBackgroundImageProps
    ? quiltWrapper?.header.items.find(item => item.id === EndUserComponent.BANNER_WIDGET)?.props
        ?.backgroundImageProps ?? {}
    : {};
  const finalBackgroundImageProps: BackgroundImageProps = {
    backgroundSize: BackgroundSizeEnum.Cover,
    backgroundPosition: BackgroundPositionEnum.CenterCenter,
    backgroundRepeat: BackgroundRepeatEnum.NoRepeat,
    ...inheritedBackgroundImageProps,
    ...backgroundImageProps
  };

  // background image and color are applied via the React inline style tag
  const sectionBgImageStyleObject: BackgroundCssProperties = {};

  if (finalBackgroundImageProps?.assetName) {
    const { assetName, backgroundPosition, backgroundSize, backgroundRepeat, lastModified } =
      finalBackgroundImageProps;
    const timestamp =
      typeof lastModified === 'string' ? lastModified : LastModifiedKeys.DEFAULTS_ONLY;
    const imageUrl: string =
      backgroundImageUrl ??
      getQuiltAssetURL(
        tenant,
        quiltWrapperId,
        assetName,
        AssetFeature.QUILT_WRAPPERS,
        timestamp,
        branchName
      );

    sectionBgImageStyleObject.background = `url(${imageUrl}) ${BackgroundPosition[backgroundPosition]} / ${BackgroundSize[backgroundSize]} ${BackgroundRepeat[backgroundRepeat]}`;
  }

  // background color
  if (backgroundColor) {
    const { background } = sectionBgImageStyleObject;
    const computedBackgroundColor = `${
      backgroundColor !== CommonColorCssVariables.TRANSPARENT ? backgroundColor : ''
    } ${background || ''}`.trim();
    if (computedBackgroundColor) {
      sectionBgImageStyleObject.background = computedBackgroundColor;
    }
  }

  // background opacity used by widgets in quilt wrapper sections
  if (
    (!!finalBackgroundImageProps?.assetName ||
      backgroundColor !== CommonColorCssVariables.TRANSPARENT) &&
    backgroundOpacity &&
    !!sectionBgImageStyleObject.background
  ) {
    sectionBgImageStyleObject.opacity = `${backgroundOpacity / 100}`;
  }

  return {
    sectionBackgroundStyles: sectionBgImageStyleObject
  };
}
