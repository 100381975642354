import PageContext from '@aurora/shared-client/components/context/PageContext/PageContext';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import WidgetContext from '@aurora/shared-client/components/context/WidgetContext';
import ErrorBoundary from '@aurora/shared-client/components/error/ErrorBoundary/ErrorBoundary';
import type { QuiltComponent as QuiltComponentType } from '@aurora/shared-generated/types/graphql-schema-types';
import { isCustomQuiltId } from '@aurora/shared-utils/helpers/quilts/QuiltHelper';
import TenantHelper from '@aurora/shared-utils/helpers/TenantHelper';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext } from 'react';
import { Alert, useClassNameMapper } from 'react-bootstrap';
import endUserComponentRegistry from '../../../features/endUserComponentRegistry';
import { isCustomComponent } from '../../../helpers/quilt/WidgetHelper';
import useInView from '../../useInView';
import localStyles from './QuiltComponent.module.css';

const CustomComponent = dynamic(
  () => import('../../customComponent/CustomComponent/CustomComponent')
);

const QuiltComponentError = dynamic(() => import('./QuiltComponentError'));

const log = getLog(module);

interface Props {
  /**
   * The quilt component
   */
  component: Omit<QuiltComponentType, '__typename'>;

  /**
   * @callback
   * Callback that should be called within `useEffect` of a widget if the widget is guaranteed not to render based on
   * some state. If visible pass the argument as true else false.
   * This is used to decide whether the section encompassing the widgets should show or hide. For widgets that
   * are guaranteed to always render, this does not need to be called.
   */
  isVisible: (visibilityStatus: boolean) => void;

  /**
   * Lazy load this element if true.
   */
  lazyLoad?: boolean;

  /**
   * Whether the quilt component should be rendered in edit mode or not.
   */
  isEditMode?: boolean;
}

const allowedComponents = process.env.NEXT_PUBLIC_ALLOWED_COMPONENTS ?? '';
const allowedComponentsSet = new Set(allowedComponents ? allowedComponents.split(' ') : []);
const isDev = process.env.NODE_ENV === 'development';

/**
 * Renders an individual quilt component.
 *
 * @constructor
 *
 * @author Jonathan Bridges
 */
const QuiltComponent: React.FC<React.PropsWithChildren<Props>> = ({
  component,
  isVisible,
  lazyLoad,
  isEditMode = false
}) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { pageId } = useContext(PageContext);
  const quilt = useContext(QuiltContext);
  const id = component?.id;
  const [inViewRef, inView] = useInView<HTMLDivElement>();
  const widget = endUserComponentRegistry.getWidgetDescriptor(id);
  const instanceId = component.props?.instanceId;

  const showErrors = isEditMode || TenantHelper.isDevelopmentPhase(tenant);

  if (isDev && allowedComponentsSet.size > 0 && !allowedComponentsSet.has(id)) {
    log.trace(`Dev mode, skip rendering for ${id}`);
    return null;
  }

  if (widget) {
    // instance ids can be a required field when the widget is defined in its feature class
    if (widget.isInstanceIdRequired && !instanceId) {
      throw new Error(`Widget with id ${id} requires a unique instanceId for its Props interface`);
    }

    const { component: ComponentElement } = widget;

    const { allowedPages } = endUserComponentRegistry.getWidgetDescriptor(id);
    const isAllowedWidget =
      isCustomQuiltId(quilt?.id) || !allowedPages || allowedPages.includes(pageId);

    if (!isCustomComponent(id) && !isAllowedWidget) {
      log.error(
        `widget with id ${id} is not allowed in the page. Update the 'allowedPages' property in widget registry for the widget`
      );
    }

    if (ComponentElement) {
      if (isAllowedWidget && (isEditMode || (!component.props?.lazyLoad && !lazyLoad) || inView)) {
        return (
          <WidgetContext.Provider value={{ id, instanceId }}>
            <ErrorBoundary
              fallbackComponent={() =>
                showErrors ? <QuiltComponentError componentId={id} instanceId={instanceId} /> : null
              }
            >
              <ComponentElement
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...component.props}
                isVisible={isVisible}
                className={cx(component.className, {
                  'lia-component-edit-mode': isEditMode
                })}
              />
            </ErrorBoundary>
          </WidgetContext.Provider>
        );
      } else {
        return <div ref={inViewRef}></div>;
      }
    } else {
      log.error('No component exists with the id: %s', id);
      if (process.env.NODE_ENV !== 'production') {
        return (
          <Alert variant="danger">
            <h6>
              Failed to load component, is it dynamically loaded via its package index.ts file?
            </h6>
            <pre>{JSON.stringify(component, null, '  ')}</pre>
          </Alert>
        );
      } else {
        return null;
      }
    }
  } else {
    if (isCustomComponent(id)) {
      return (
        <WidgetContext.Provider value={{ id, instanceId }}>
          <ErrorBoundary
            fallbackComponent={() =>
              showErrors ? <QuiltComponentError componentId={id} instanceId={instanceId} /> : null
            }
          >
            <CustomComponent
              customComponentId={id}
              isVisible={isVisible}
              className={cx({
                'lia-component-edit-mode': isEditMode
              })}
              //eslint-disable-next-line react/jsx-props-no-spreading
              {...component.props}
            />
          </ErrorBoundary>
        </WidgetContext.Provider>
      );
    } else {
      log.error(`Unable to find widget with id ${id}`);
      return null;
    }
  }
};
export default QuiltComponent;
