import type {
  BackgroundImageProps,
  QuiltComponent as QuiltComponentType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { merge } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import useWrapperSection from '../../useWrapperSection';

/**
 * hook used for shared properties of the EditableQuiltWrapperSection and QuiltWrapperSection components
 *
 * @param wrapperId the quilt wrapper id
 * @param backgroundImageProps the background image props for the quilt wrapper section
 * @param backgroundColor the background color for the quilt wrapper section
 * @param componentProps the component props overrides for the quilt wrapper section
 * @param bgImageUrl the background image url
 */
export default function useQuiltWrapperSection(
  wrapperId: string,
  backgroundImageProps: BackgroundImageProps,
  backgroundColor: string,
  componentProps: JSON,
  bgImageUrl?: string
) {
  const { sectionBackgroundStyles } = useWrapperSection(
    wrapperId,
    {
      backgroundImageProps,
      backgroundColor
    },
    null,
    bgImageUrl
  );

  function mergeWidgetProps(widget: QuiltComponentType): QuiltComponentType {
    if (!!componentProps && componentProps[widget.id]) {
      const propOverridesFromContainer: JSON = componentProps[widget.id];
      return {
        ...widget,
        props: merge(widget.props, propOverridesFromContainer)
      };
    }

    return widget;
  }

  return {
    sectionBackgroundStyles,
    mergeWidgetProps
  };
}
