import type {
  QuiltComponent,
  QuiltContainerSectionProps,
  QuiltWrapper,
  QuiltWrapperSection
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { QuiltWrapperFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { QuiltWrapperWidgetLocation } from '../../components/context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import type { WidgetDescriptor as ExternalWidgetDescriptor } from '@aurora/external-types/feature';
import type { QuiltWrapperWidget } from '../../components/pageeditor/QuiltWrapperRenderer/types';
import { QuiltWrapperSectionType } from '../../components/pageeditor/QuiltWrapperRenderer/types';
import endUserComponentRegistry from '../../features/endUserComponentRegistry';

const log = getLog(module);

/**
 * Returns the final set of components to render in the section in order based on the sectionOverrides and the
 * section components passed
 * @param sectionComponents the default section components in the container
 * @param sectionOverrides the overrides
 */
export function getQuiltWrapperComponents(
  sectionComponents: QuiltComponent[],
  sectionOverrides?: Pick<
    QuiltContainerSectionProps,
    'addComponents' | 'removeComponents' | 'componentOrder'
  >
): QuiltComponent[] {
  if (!sectionOverrides) {
    return sectionComponents;
  }

  const { addComponents, removeComponents, componentOrder } = sectionOverrides;

  let adjustedSectionComponents = [...sectionComponents];
  if (removeComponents?.length) {
    adjustedSectionComponents = adjustedSectionComponents.filter(
      component => !removeComponents.includes(component.id)
    );

    if (sectionComponents.length - removeComponents.length !== adjustedSectionComponents.length) {
      log.error(
        `One of the component specified in removeComponents is not present in section component. Remove component: ${JSON.stringify(
          removeComponents
        )}`
      );
    }
  }

  if (addComponents?.length) {
    const addComponentObjects = addComponents.map(id => {
      return {
        id
      };
    });
    adjustedSectionComponents = [...adjustedSectionComponents, ...addComponentObjects];
  }

  if (componentOrder?.length) {
    adjustedSectionComponents = adjustedSectionComponents.sort((component1, component2) => {
      const component1Idx: number = componentOrder.indexOf(component1.id);
      const component2Idx: number = componentOrder.indexOf(component2.id);
      return component1Idx - component2Idx;
    });
  }

  return adjustedSectionComponents;
}

/**
 * Returns a QuiltSection populating the background attributes in descending priority:
 * 1) edits made to the QuiltWrapper during an active page editor session
 * 2) overrides from mosaic specified by the QuiltContainer
 * 3) default values from the QuiltWrapper JSON
 *
 * @param defaultSection the QuiltWrapperSection from the wrapper JSON or the editedSection from the active page editor session
 * @param containerSectionProps section props/overrides from the QuiltContainer
 * @param items
 */
export function getFinalQuiltWrapperSection(
  defaultSection: QuiltWrapperSection,
  containerSectionProps: QuiltContainerSectionProps,
  items: Array<QuiltComponent>
): QuiltWrapperSection {
  return {
    items,
    backgroundColor: containerSectionProps?.backgroundColor ?? defaultSection.backgroundColor,
    backgroundImageProps: {
      ...defaultSection.backgroundImageProps,
      ...containerSectionProps?.backgroundImageProps
    }
  };
}

/**
 * Removes a widget from a {@link QuiltWrapperSection} items and sets the updated section
 *
 * @param widgetLocation the location of the widget to be removed
 * @param quiltWrapper the quilt wrapper
 */
export function deleteWidget(
  widgetLocation: QuiltWrapperWidgetLocation,
  quiltWrapper: QuiltWrapper
): QuiltWrapper {
  const { sectionId, widgetIdx } = widgetLocation;
  const updatedQuiltWrapper: QuiltWrapper = deepClone(quiltWrapper);
  const {
    header: { items: headerItems },
    footer: { items: footerItems }
  } = updatedQuiltWrapper;

  if (sectionId === QuiltWrapperSectionType.HEADER) {
    headerItems.splice(widgetIdx, 1);
  }
  if (sectionId === QuiltWrapperSectionType.FOOTER) {
    footerItems.splice(widgetIdx, 1);
  }

  return updatedQuiltWrapper;
}

/**
 * Replaces a widget by another in a quilt wrapper section.
 * @param quiltWrapper the quilt wrapper to replaces the widget.
 * @param location the widget location.
 * @param widgetId the id of the widget to put in the section.
 * @param instanceId the instance id for the replacement widget
 */
function replaceWidget(
  quiltWrapper: QuiltWrapperFragment,
  location: QuiltWrapperWidgetLocation,
  widgetId: string,
  instanceId?: string
): QuiltWrapperFragment {
  const quiltWrapperClone: QuiltWrapperFragment = deepClone(quiltWrapper);
  const section: QuiltWrapperSection = quiltWrapperClone[location.sectionId];

  section.items.splice(location.widgetIdx, 1, {
    id: widgetId,
    ...(instanceId && { props: { instanceId } })
  });

  return quiltWrapperClone;
}

/**
 * Replaces the section widget that is currently being edited with translationBundleOverride new section widget,
 * updating the quilt wrapper in the page preview area, and also replacing the Edit Widget configuration form
 * displayed by this component
 *
 * @param replacementWidgetId replacement widget id
 * @param location location of the replacement
 * @param quiltWrapper quilt wrapper being worked on
 */
export function replaceSectionWidget(
  replacementWidgetId: string,
  location: QuiltWrapperWidgetLocation,
  quiltWrapper: QuiltWrapperFragment
) {
  const widgetId: string = replacementWidgetId.startsWith('custom.widget')
    ? EndUserComponent.CUSTOM_COMPONENT_WIDGET
    : replacementWidgetId;
  const replacementWidgetDescriptor: ExternalWidgetDescriptor =
    endUserComponentRegistry.getWidgetDescriptor(widgetId);
  // if the widget being added requires an instance id and is being added via page editor, add it now
  const createdInstanceId: string | null = replacementWidgetDescriptor.isInstanceIdRequired
    ? `${replacementWidgetId}-${Date.now()}`
    : null;

  const { sectionId, widgetIdx } = location;
  const updatedQuiltWrapper = replaceWidget(
    quiltWrapper,
    location,
    replacementWidgetId,
    createdInstanceId
  );

  const replacementWidgetData: QuiltWrapperWidget = {
    widgetId: replacementWidgetId,
    location: {
      sectionId,
      widgetIdx
    },
    props: {
      instanceId: createdInstanceId
    }
  };

  return { updatedQuiltWrapper, nextSelection: replacementWidgetData };
}
