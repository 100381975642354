import type { ApolloError } from '@apollo/client';
import useCachedAsset from '@aurora/shared-client/components/assets/useCachedAsset';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useCachedQuiltBundle from '@aurora/shared-client/components/useCachedQuiltBundle';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { CachedAssetType } from '@aurora/shared-types/overrides/enums';
import { deepClone } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { useContext, useMemo } from 'react';

interface QuiltResult {
  /**
   * The quilt that is requested.
   */
  quilt: QuiltFragment;

  /**
   * Whether the request is completed or not. If completed, it is set to false.
   */
  loading: boolean;

  /**
   * The Apollo error
   */
  error?: ApolloError;
}

/**
 * Returns the current quilt and the default Quilt for a page and node, or null if the quilt call is still loading.
 *
 * @param pageId
 * @param nodeId
 * @param module The module
 * @param getDefault boolean parameter to return the default Quilt optionally.
 * @param skip whether the query should be skipped or not.
 *
 * @return a Quilt or a default Quilt for the provided pageId.
 */
export default function useCachedQuilt(
  module: NodeModule | string,
  pageId: string,
  nodeId: string,
  getDefault = false,
  skip = false
): QuiltResult {
  const appContext = useContext(AppContext);
  const quiltLastModified = getDefault
    ? LastModifiedKeys.DEFAULTS_ONLY
    : appContext.quiltLastModified;
  const cachedBundle = useCachedQuiltBundle(pageId, nodeId, quiltLastModified);

  const finalSkip = skip || !!cachedBundle;

  const {
    loading: quiltLoading,
    data: quiltData,
    error
  } = useCachedAsset(pageId, CachedAssetType.QUILT, quiltLastModified, nodeId, null, finalSkip);

  const quilt: QuiltFragment = cachedBundle?.value ?? quiltData?.cachedAsset?.value ?? null;

  const transformedQuilt: QuiltFragment = useMemo(() => {
    return quilt ? deepClone(quilt) : null;
  }, [quilt]);

  return {
    loading: !finalSkip ? quiltLoading : false,
    quilt: transformedQuilt,
    error
  };
}
