import type {
  ElementWrapper,
  QuiltFooter,
  QuiltHeader,
  QuiltList
} from '@aurora/shared-generated/types/graphql-schema-types';
import { QuiltItemType } from '@aurora/shared-generated/types/graphql-schema-types';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SKIP_CONTENT } from '../../community/Navbar/types';
import EditContext from '../../context/EditContext/EditContext';
import QuiltComponent from '../QuiltComponent/QuiltComponent';
import QuiltListComponent from '../QuiltList/QuiltListComponent';

interface Props {
  /**
   * The quilt item
   */
  item: QuiltList | QuiltHeader | QuiltFooter;

  /**
   * Id of the quilt
   */
  quiltId: string;

  /**
   * Lazy load this item
   */
  lazyLoad?: boolean;

  /**
   * A list item threshold to start lazy loading.
   */
  lazyLoadThreshold?: number;
}

/**
 * A switch component that renders a quilt item depending on its type.
 *
 * @constructor
 *
 * @author Jonathan Bridges
 */
const QuiltItem: React.FC<React.PropsWithChildren<Props>> = props => {
  const { quilt: isEditMode } = useContext(EditContext);
  const { item, quiltId, lazyLoad, lazyLoadThreshold } = props;
  const { type } = item;
  const cx = useClassNameMapper();

  const renderItem = (): React.ReactElement => {
    switch (type) {
      case QuiltItemType.Header: {
        return (
          <QuiltComponent
            component={item}
            isVisible={() => {}}
            lazyLoad={lazyLoad}
            isEditMode={!!isEditMode}
          />
        );
      }

      case QuiltItemType.Footer: {
        return (
          <QuiltComponent
            component={item}
            isVisible={() => {}}
            lazyLoad={lazyLoad}
            isEditMode={!!isEditMode}
          />
        );
      }
      case QuiltItemType.List: {
        const itemAsQuiltList = item as QuiltList;
        const { wrapper } = itemAsQuiltList;
        if (wrapper) {
          const { element, className: wrapperClassName } = wrapper as ElementWrapper;
          const Component = element as React.ElementType;

          return (
            <Component className={cx(wrapperClassName)} id={SKIP_CONTENT}>
              <QuiltListComponent
                list={itemAsQuiltList}
                quiltId={quiltId}
                lazyLoad={lazyLoad}
                lazyLoadThreshold={lazyLoadThreshold}
              />
            </Component>
          );
        }
        return (
          <QuiltListComponent
            list={itemAsQuiltList}
            quiltId={quiltId}
            lazyLoad={lazyLoad}
            lazyLoadThreshold={lazyLoadThreshold}
          />
        );
      }
      default: {
        return (
          <QuiltComponent
            component={item}
            isVisible={() => {}}
            lazyLoad={lazyLoad}
            isEditMode={!!isEditMode}
          />
        );
      }
    }
  };

  return <React.Fragment>{renderItem()}</React.Fragment>;
};
export default QuiltItem;
