import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import React, { useContext } from 'react';
import QuiltItem from '../QuiltItem/QuiltItem';
import type { FirstSectionComponents } from './QuiltContainerRenderer';
import QuiltContainerRenderer from './QuiltContainerRenderer';

interface Props {
  /**
   * The quilt to display
   */
  quilt: QuiltFragment;
  /**
   * The list of components to render as part of the first visible section of the quilt
   */
  componentsToRenderFirstInFirstVisibleSection?: FirstSectionComponents;
}
/**
 * Renders a layout with components based on the <code>quilt</code> parameter.
 * The layout is rendered using react-bootstrap Container, Row, and Col components,
 * ultimately rendering a Bootstrap grid layout.
 *
 * All the parameters for the [react-bootstrap grid](https://react-bootstrap.github.io/layout/grid/#grid-props)
 * components are supported by the structure of the {@link Quilt} parameter.
 *
 * @author Adam Ayres, Jonathan Bridges, Luisina Santos
 */
const QuiltRenderer: React.FC<React.PropsWithChildren<Props>> = ({
  quilt,
  componentsToRenderFirstInFirstVisibleSection
}) => {
  const {
    publicConfig: { quiltsV2Enabled }
  } = useContext(TenantContext);

  if (quiltsV2Enabled) {
    return (
      <QuiltContainerRenderer
        quilt={quilt}
        componentsToRenderFirstInFirstVisibleSection={componentsToRenderFirstInFirstVisibleSection}
      />
    );
  }

  const items = quilt?.items ?? [];
  const lazyLoadThreshold = Number(process.env.NEXT_PUBLIC_QUILT_ITEM_SSR_THRESHOLD ?? 2);

  return (
    <QuiltContext.Provider value={quilt}>
      {items.map((quiltItem, i) => {
        return (
          <QuiltItem
            key={quiltItem.id}
            item={quiltItem}
            quiltId={quilt.id}
            lazyLoad={i > lazyLoadThreshold}
            lazyLoadThreshold={lazyLoadThreshold}
          />
        );
      })}
    </QuiltContext.Provider>
  );
};

export default QuiltRenderer;
