import IntlWrapperContext from '@aurora/shared-client/components/context/IntlContext/IntlWrapperContext';
import QuiltContext from '@aurora/shared-client/components/context/QuiltContext';
import QuiltWrapperContext from '@aurora/shared-client/components/context/QuiltWrapperContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import type { QuiltWrapperSection } from '@aurora/shared-generated/types/graphql-schema-types';
import { QuiltItemType } from '@aurora/shared-generated/types/graphql-schema-types';
import type { QuiltFragment } from '@aurora/shared-generated/types/graphql-types';
import { LastModifiedKeys } from '@aurora/shared-types/assets';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import {
  getFinalQuiltWrapperSection,
  getQuiltWrapperComponents
} from '../../../helpers/quilt/QuiltWrapperHelper';
import { AssetFeature, getQuiltAssetURL } from '../../../helpers/util/AssetHelper';
import { SKIP_CONTENT } from '../../community/Navbar/types';
import EditContext from '../../context/EditContext/EditContext';
import PageEditorPublishContext from '../../pageeditor/PageEditorPublishContext/PageEditorPublishContext';
import { QuiltWrapperSectionType } from '../../pageeditor/QuiltWrapperRenderer/types';
import useCachedQuiltWrapper from '../../useCachedQuiltWrapper';
import QuiltListComponent from '../QuiltList/QuiltListComponent';
import QuiltWrapperSectionComponent from '../QuiltWrapperSection/QuiltWrapperSection';

const log = getLog(module);

export interface FirstSectionComponents {
  /**
   * The component to render.
   */
  components: React.FC<React.PropsWithChildren<unknown>>[];
  /**
   * Whether the first component should be attached to the header or not. If not, all components use the section markups.
   */
  attachFirstComponentToTop: boolean;
}

interface Props {
  /**
   * The quilt to display
   */
  quilt: QuiltFragment;
  /**
   * The list of components to render as part of the first visible section of the quilt
   */
  componentsToRenderFirstInFirstVisibleSection?: FirstSectionComponents;
}
/**
 * Renders a layout with components based on the <code>quilt</code> parameter.
 * The layout is rendered using react-bootstrap Container, Row, and Col components,
 * ultimately rendering a Bootstrap grid layout.
 *
 * All the parameters for the [react-bootstrap grid](https://react-bootstrap.github.io/layout/grid/#grid-props)
 * components are supported by the structure of the {@link QuiltContainer} specification
 *
 * @author Luisina Santos
 */
const QuiltContainerRenderer: React.FC<React.PropsWithChildren<Props>> = ({
  quilt,
  componentsToRenderFirstInFirstVisibleSection
}) => {
  const tenant = useContext(TenantContext);
  const { editedQuiltWrappers } = useContext(PageEditorPublishContext);
  const { quilt: isEditMode } = useContext(EditContext);
  const { locale } = useContext(IntlWrapperContext);

  const {
    container: { id, items, headerProps, footerProps, headerComponentProps, footerComponentProps }
  } = quilt;

  const editedWrapper = editedQuiltWrappers.find(eqw => eqw.quiltWrapper.id === id);

  const {
    wrapper: quiltWrapper,
    loading,
    error
  } = useCachedQuiltWrapper(module, id, isEditMode ? null : locale, false, !!editedWrapper);
  const { branchName } = useContext(SwitchBranchContext);

  if (loading || error) {
    if (error) {
      log.error('Error getting default quilt wrapper', error);
    }
    return null;
  }

  const lazyLoadThreshold = Number(process.env.NEXT_PUBLIC_QUILT_ITEM_SSR_THRESHOLD ?? 2);

  const headerSection: QuiltWrapperSection = getFinalQuiltWrapperSection(
    editedWrapper?.quiltWrapper?.header ?? quiltWrapper.header,
    headerProps,
    getQuiltWrapperComponents(
      editedWrapper?.quiltWrapper.header.items ?? quiltWrapper.header.items,
      headerProps
    )
  );
  const footerSection: QuiltWrapperSection = getFinalQuiltWrapperSection(
    (editedQuiltWrappers && editedQuiltWrappers[0]?.quiltWrapper?.footer) ?? quiltWrapper.footer,
    footerProps,
    getQuiltWrapperComponents(
      editedWrapper?.quiltWrapper.footer.items ?? quiltWrapper.footer.items,
      footerProps
    )
  );

  const headerBgImageUrl: string = !!headerProps?.backgroundImageProps?.assetName
    ? getQuiltAssetURL(
        tenant,
        quilt.id,
        headerProps.backgroundImageProps.assetName,
        AssetFeature.QUILTS,
        typeof headerProps.backgroundImageProps.lastModified === 'string'
          ? headerProps.backgroundImageProps.lastModified
          : LastModifiedKeys.DEFAULTS_ONLY,
        branchName
      )
    : null;
  const footerBgImageUrl: string = !!footerProps?.backgroundImageProps?.assetName
    ? getQuiltAssetURL(
        tenant,
        quilt.id,
        footerProps.backgroundImageProps.assetName,
        AssetFeature.QUILTS,
        typeof footerProps.backgroundImageProps.lastModified === 'string'
          ? footerProps.backgroundImageProps.lastModified
          : LastModifiedKeys.DEFAULTS_ONLY,
        branchName
      )
    : null;

  return (
    <QuiltWrapperContext.Provider value={editedWrapper ? editedWrapper.quiltWrapper : quiltWrapper}>
      <QuiltContext.Provider value={quilt}>
        {headerSection.items.length > 0 && (
          <QuiltWrapperSectionComponent
            wrapperId={editedWrapper?.quiltWrapper.id ?? quiltWrapper.id}
            type={QuiltWrapperSectionType.HEADER}
            section={headerSection}
            componentProps={headerComponentProps}
            bgImageUrl={headerBgImageUrl}
          />
        )}
        {items?.length > 0 && (
          <main id={SKIP_CONTENT}>
            <QuiltListComponent
              list={{ id: 'main', type: QuiltItemType.List, items }}
              quiltId={quilt.id}
              lazyLoad={1 > lazyLoadThreshold}
              lazyLoadThreshold={lazyLoadThreshold}
              componentsToRenderFirstInFirstVisibleSection={
                componentsToRenderFirstInFirstVisibleSection
              }
            />
          </main>
        )}
        {footerSection.items.length > 0 && (
          <QuiltWrapperSectionComponent
            wrapperId={editedWrapper?.quiltWrapper.id ?? quiltWrapper.id}
            type={QuiltWrapperSectionType.FOOTER}
            section={footerSection}
            componentProps={footerComponentProps}
            bgImageUrl={footerBgImageUrl}
          />
        )}
      </QuiltContext.Provider>
    </QuiltWrapperContext.Provider>
  );
};

export default QuiltContainerRenderer;
