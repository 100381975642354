import type { MutableRefObject } from 'react';
import { useCallback, useRef } from 'react';
import { useInView as useInViewHook } from 'react-intersection-observer';

/**
 * A hook that triggers a state change when a specified DOM element comes into view.
 *
 * @author Dolan Halbrook
 */
export default function useInView<T extends Element>(): [
  (node?: T | null) => void,
  boolean,
  MutableRefObject<T>
] {
  const ref = useRef();
  const { ref: inViewRef, inView } = useInViewHook({
    threshold: 0,
    triggerOnce: true
  });
  const setReferences = useCallback(
    node => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );
  return [setReferences, inView, ref];
}
