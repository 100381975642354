import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { QuiltList } from '@aurora/shared-generated/types/graphql-schema-types';
import dynamic from 'next/dynamic';
import React, { useContext, useRef } from 'react';
import type { SectionType } from '../QuiltSection/QuiltSection';
import QuiltSection from '../QuiltSection/QuiltSection';
import type { FirstSectionComponents } from '../Quilt/QuiltContainerRenderer';
import EditContext from '../../context/EditContext/EditContext';

const EditableQuiltList = dynamic(() => import('./EditableQuiltList'));

export interface QuiltListComponentProps {
  /**
   * The quilt list
   */
  list: QuiltList;

  /**
   * Id of the quilt
   */
  quiltId: string;

  /**
   * Lazy load this element if true.
   */
  lazyLoad?: boolean;

  /**
   * A list item threshold to start lazy loading.
   */
  lazyLoadThreshold?: number;

  /**
   * The list of components to render as part of the first section of the quilt
   */
  componentsToRenderFirstInFirstVisibleSection?: FirstSectionComponents;
}

/**
 * A component that renders the sections inside the quilt list
 *
 * @author Jonathan Bridges
 */
const QuiltListComponent: React.FC<React.PropsWithChildren<QuiltListComponentProps>> = ({
  list,
  quiltId,
  lazyLoad,
  lazyLoadThreshold,
  componentsToRenderFirstInFirstVisibleSection
}) => {
  const { quilt: isEditMode, showEditControls } = useContext(EditContext);
  const defaultState = [];
  if (componentsToRenderFirstInFirstVisibleSection?.components.length > 0) {
    for (let index = 0; index < list.items.length; index++) {
      defaultState[index] = list.items[index].id;
    }
  }
  const sectionRenderRef = useRef(defaultState);
  const { contextNode } = useContext(AppContext);

  return isEditMode && showEditControls ? (
    <EditableQuiltList list={list} quiltId={quiltId} />
  ) : (
    <>
      {list.items.map((section: SectionType, i) => (
        <QuiltSection
          key={`${section.id}-${contextNode.id}`}
          section={section}
          quiltId={quiltId}
          lazyLoad={lazyLoad || i > lazyLoadThreshold}
          componentsToRenderFirstInFirstVisibleSection={
            componentsToRenderFirstInFirstVisibleSection
          }
          onSectionInvisible={id => {
            const newState = [];
            for (const element of sectionRenderRef.current) {
              if (id !== element) {
                newState.push(element);
              }
            }
            sectionRenderRef.current = newState;
          }}
          firstVisibleSectionId={
            sectionRenderRef.current.length > 0 ? sectionRenderRef.current[0] : null
          }
        />
      ))}
    </>
  );
};
export default QuiltListComponent;
