import TextVariantContext from '@aurora/shared-client/components/context/TextVariantContext';
import type { QuiltComponent as QuiltComponentType } from '@aurora/shared-generated/types/graphql-schema-types';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import QuiltWrapperWidgetLocationContext from '../../context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import QuiltComponent from '../QuiltComponent/QuiltComponent';
import localStyles from './QuiltWrapperSection.module.pcss';
import type { SectionWrapperProps } from './types';
import useQuiltWrapperSection from './useQuiltWrapperSection';
import MultiThemeContext from '@aurora/shared-client/components/context/MultiThemeContext/MultiThemeContext';
import { AppType } from '@aurora/shared-types/app';
import useDynamicCss from '../../useDynamicCss';
import { canUseDOM } from 'exenv';
import { FrameContext } from 'react-frame-component';

/**
 * Renders the quilt wrapper section {@link QuiltWrapperSection} (header or footer) as single column rows
 *
 * @author Luisina Santos, Jonathan Bridges
 */
const QuiltWrapperSectionComponent: React.FC<React.PropsWithChildren<SectionWrapperProps>> = ({
  wrapperId,
  type,
  section,
  componentProps,
  className,
  bgImageUrl
}) => {
  const cx = useClassNameMapper(localStyles);
  const textVariantContext = useContext(TextVariantContext);
  const { endUserTheme } = useContext(MultiThemeContext);
  const { document: frameDocument } = useContext(FrameContext);
  const { items, backgroundImageProps, backgroundColor } = section;
  const ComponentElement = type;
  const styleScope = endUserTheme ? `[data-lia-styles=${AppType.END_USER}], :root` : ':root:root';

  const { sectionBackgroundStyles, mergeWidgetProps } = useQuiltWrapperSection(
    wrapperId,
    backgroundImageProps,
    backgroundColor,
    componentProps,
    bgImageUrl
  );

  const refSetter = useDynamicCss(
    (sheet, entry) => {
      const containerHeight = entry?.borderBoxSize?.[0]?.blockSize;
      sheet.insertRule(`${styleScope} { --lia-d-header-height: ${containerHeight}px }`);
    },
    true,
    canUseDOM ? frameDocument : undefined
  );

  return (
    <ComponentElement
      data-testid="QuiltWrapperSection"
      style={sectionBackgroundStyles}
      className={cx(className)}
      ref={element => refSetter(element)}
    >
      {items?.map((component: QuiltComponentType, idx) => {
        const adjustedComponent = mergeWidgetProps(component);
        const { id, props } = adjustedComponent;

        return (
          <TextVariantContext.Provider
            key={
              props?.instanceId
                ? `${type}-${id}-${props.instanceId}-${idx}`
                : `${type}-${id}-${idx}`
            }
            value={{ ...textVariantContext, instance: props?.instanceId }}
          >
            <QuiltWrapperWidgetLocationContext.Provider value={{ sectionId: type, widgetIdx: idx }}>
              <QuiltComponent
                component={adjustedComponent}
                isVisible={() => true}
                lazyLoad={false}
                isEditMode={false}
              />
            </QuiltWrapperWidgetLocationContext.Provider>
          </TextVariantContext.Provider>
        );
      })}
    </ComponentElement>
  );
};

export default QuiltWrapperSectionComponent;
